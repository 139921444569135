/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

import axios from "axios";
window.axios = axios;

window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

import Pusher from "pusher-js";
window.Pusher = Pusher;

window.Apex = {
    chart: {
        defaultLocale: "pt-BR",
        locales: [
            {
                name: "pt-BR",
                options: {
                    months: [
                        "Janeiro",
                        "Fevereiro",
                        "Março",
                        "Abril",
                        "Maio",
                        "Junho",
                        "Julho",
                        "Agosto",
                        "Setembro",
                        "Outubro",
                        "Novembro",
                        "Dezembro",
                    ],
                    shortMonths: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"],
                    days: ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"],
                    shortDays: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
                    toolbar: {
                        exportToSVG: "Baixar SVG",
                        exportToPNG: "Baixar PNG",
                        exportToCSV: "Baixar CSV",
                        menu: "Menu",
                        selection: "Seleção",
                        selectionZoom: "Zoom na seleção",
                        zoomIn: "Aumentar zoom",
                        zoomOut: "Diminuir zoom",
                        pan: "Mover",
                        reset: "Resetar zoom",
                    },
                    noData: "Sem dados para exibir",
                },
            },
        ],
    },
};
